<template>
  <NuxtLink
      to="/addSpot"
      class="flex items-center gap-1"
      v-if="addSpotPossible"
      :target="openInNewWindow == true ? `_blank` : `_self`"
  >
    <svg
        class="h-[32px] w-[32px] sm:h-[56px] sm:w-[56px] rounded-[27.5px] transition-all duration-200"
        width="56"
        height="56"
        viewBox="0 0 55 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.5" cy="27.5" r="27.5" class="fill-theme-primary"/>
      <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.2831 26.6435H29.8615V14.9078C29.8615 13.8912 29.0597 13.0674 28.0703 13.0674C27.0809 13.0674 26.2792 13.8912 26.2792 14.9078V26.6435H14.8576C13.8682 26.6435 13.0664 27.4673 13.0664 28.4839C13.0664 29.5004 13.8682 30.3243 14.8576 30.3243H26.2792V42.0599C26.2792 43.0773 27.0809 43.9003 28.0703 43.9003C29.0597 43.9003 29.8615 43.0773 29.8615 42.0599V30.3243H41.2831C42.2732 30.3243 43.0742 29.5004 43.0742 28.4839C43.0742 27.4673 42.2732 26.6435 41.2831 26.6435Z"
          fill="white"
      />
    </svg>
    <span class="text-theme-secondary text-2xl hidden md:flex">{{
        $t("general.addspot_text")
      }}</span>
  </NuxtLink>
</template>
<script setup lang="ts">
const props = defineProps<{
  openInNewWindow?: boolean;
}>();
const route = useRoute();

const addSpotPossible = computed(() => {
  return route.path != "/addSpot";
});
</script>
<style lang="postcss" scoped>
a:hover circle {
  @apply fill-theme-secondary transition-colors duration-200;
}
</style>
